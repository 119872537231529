import React, { useState, useEffect } from 'react';
import { Checkbox, Select, Spin } from 'antd';
import Card from '../../Components/Card';

export interface Event {
    date: string;
    link: string;
    location: string;
    name: string;
    places: string;
    type: string;
}

const Home = () => {
    const [events, setEvents] = useState<Event[] | undefined>(undefined);
    const [baseEvents, setBaseEvents] = useState<Event[]>([]);
    const [types, setTypes] = useState<string[]>([]);
    const [locations, setLocations] = useState<string[]>([]);
    const [filters, setFilters] = useState<{ type: string, location: string, ignoreFull: boolean } | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getEvents = async () => {
        const response = await fetch(process.env.REACT_APP_BACK_URL);
        const res = await response.json();
        setBaseEvents(res);
        setTypes([...new Set(res.map((event: any) => event.type)) as any]);
        setLocations([...new Set(res.map((event: any) => event.location)) as any]);
    };

    useEffect(() => {
        getEvents();
    }, []);

    useEffect(() => {
        if (filters) {
            let newEvents = baseEvents;
            for (const [key, value] of Object.entries(filters)) {
                if (key === 'ignoreFull' && value) {
                    newEvents = newEvents.filter((item) => item.places !== '0 restante');
                }
                if ((key === 'location' || key === 'type') && Array.isArray(value) && value.length) {
                    newEvents = newEvents.filter((item) => value.includes(item[key]));
                }
            }
            setEvents(newEvents);    
        } else {
            setEvents(baseEvents);
        }
    }, [filters]);

    useEffect(() => {
        const localStorageFilters = localStorage.getItem('filtersCaverne');
        if (localStorageFilters) {
            setFilters(JSON.parse(localStorageFilters));
        } else {
            setEvents(baseEvents);
        }
    }, [baseEvents]);

    useEffect(() => {
        if (events && events.length > 0) {
            setIsLoading(false);
        }
    }, [events]);

    const addFilter = (propriety: string, value: string | boolean) => {
        const newFilters = { ...filters, [propriety]: value };
        setFilters(newFilters);
        localStorage.setItem('filtersCaverne', JSON.stringify(newFilters));
    };

    return (
        <div className="flex flex-col">
            <div className="flex h-[5vh] w-full bg-[#202124] text-white justify-center items-center">
                Le site de la caverne mais en mieux
            </div>
            {isLoading ? <Spin className="mt-4" size="large" /> : <>
                <div className="flex w-[70vw] mt-4 mx-auto flex-col md:flex-row text-black gap-5">
                    <Select
                        defaultValue={filters?.type}
                        className="w-full md:w-[20%]"
                        mode="multiple"
                        allowClear
                        onChange={(e) => addFilter('type', e)}
                        placeholder="Select a game"
                        options={types.map(item => ({ label: item, value: item }))}
                    />
                    <Select
                        defaultValue={filters?.location}
                        className="w-full md:w-[20%]"
                        mode="multiple"
                        allowClear
                        onChange={(e) => addFilter('location', e)}
                        placeholder="Select a location"
                        options={locations.map(item => ({ selected: true, label: item, value: item }))}
                    />
                    <Checkbox defaultChecked={filters?.ignoreFull} onChange={(e) => addFilter('ignoreFull', e.target.checked)}>
                        Ignore if full
                    </Checkbox>
                </div>
                <div className="grid 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-[20px] p-5">
                    {events.length !== 0 && events.map(item =>
                        <div
                            key={item.link}
                            className="flex"
                        >
                            <Card key={item.link} item={item} />
                        </div>
                    )}
                </div>
            </>}
        </div>
    );
};

export { Home as ScraperCaverne };