import './FullPageImage.css';

interface FullPageImageProps {
    title?: string;
    image?: string;
}

export default ({title, image}: FullPageImageProps) => {
    console.log(image);
    return (
        <div className="full-page-image-container" style={{ backgroundImage: `url(${image})` }}>
            {title && <div className="full-page-image-caption">
                <span className="full-page-image-span">{title}</span>
            </div>}
        </div>
    );
}
