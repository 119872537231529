import React from 'react';
import { Progress } from 'antd';
import { Unity, useUnityContext } from 'react-unity-webgl';
import './Roguelike.css';

export default () => {
    // https://react-unity-webgl.dev/docs/api/unload/
    const buildFolder = 'assets/roguelike_build/';
    const { unityProvider, isLoaded, loadingProgression } = useUnityContext({
        loaderUrl: `${buildFolder}UnityLoader.js`,
        dataUrl: `${buildFolder}web.data`,
        frameworkUrl: `${buildFolder}web.framework.js`,
        codeUrl: `${buildFolder}web.wasm`,
    });

    const loadingPercentage = Math.round(loadingProgression * 100);

    return (
        <div className="roguelike-container">
            {!isLoaded && (
                <Progress
                    className="unity-app"
                    type="circle"
                    strokeColor="#8057D7"
                    percent={loadingPercentage < 10 ? 10 : loadingPercentage}
                />
            )}
            <Unity className={isLoaded ? 'unity-app' : 'no-display'} unityProvider={unityProvider} />
        </div>
    );
};
