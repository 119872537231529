import React from 'react';

interface BannerProps {
    color: 'light' | 'dark';
    children?: React.ReactNode;
}

export default ({ color, children }: BannerProps) => {
    const colorVariants = {
        'light': 'text-[#777] bg-[#fff]',
        'dark': 'text-[#ddd] bg-[#282E34]'
    };

    return (
        <div className={`flex flex-col text-justify py-[50px] md:px-[80px] px-[30px] gap-[40px] ${colorVariants[color]}`}>
            {children}
        </div>
    );
};