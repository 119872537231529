import React from 'react';
import type { Event } from '../Pages/Home/Home';

interface CardProps {
    item: Event;
}

const getColors = (type: string) => {
    switch (type) {
        case 'Yu-Gi-Oh':
            return 'bg-[#3a8a8a]';

        case 'Magic':
            return 'bg-[#f5f542]';

        case 'Lorcana':
            return 'bg-[#ad42f5]';

        case 'Pokémon':
            return 'bg-[#f59042]';

        case 'One Piece':
            return 'bg-[#8a4e3a]';

        case 'Flesh and Blood':
            return 'bg-[#8a3a4e]';

        case 'Force of Will':
            return 'bg-[#42f5a4]';

        default:
            return null;
    }
};

export default ({ item }: CardProps) => {
    const { name, date, places, type, location, link } = item;
    const backgroundColor = getColors(type);
    return (
        <a
            href={`https://lesanimationsdugobelin.com/${link}`}
            className={`
                w-full h-full
                flex flex-col
                justify-between items-center
                rounded-md
                hover:opacity-95
                border-2 ${backgroundColor}
                shadow-lg
                p-4
            `}
        >
            <div className={`text-[45px] ${backgroundColor} text-center font-bold`}>{type}</div>
            <div className={`${backgroundColor} text-center tracking-wider font-bold`}>
                {date.toUpperCase()}
            </div>
            <p><span className="font-bold text-lg">{name}</span></p>
            <p><span className="font-bold">{places.substr(0, places.indexOf(' '))} places restantes</span></p>
            <div className={`${backgroundColor} text-center tracking-wider font-bold`}>
                {location.toUpperCase()}
            </div>
        </a>
    );
};