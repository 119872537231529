import { useEffect, useState } from 'react';
import { Image } from 'antd';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import FullPageImage from '../../Components/FullPageImage/FullPageImage';
import Loader from '../../Components/Loader/Loader';
import Banner from '../../Components/Banner';
import TechnologiesBlock from '../../Components/TechnologiesBlock';
import TimelineBlock from '../../Components/TimelineBlock';
import { Data } from '../../Types/Data';
import './Home.css';

export default () => {
    const [data, setData] = useState<Data|undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getData = async () => {
        const response = await fetch(process.env.REACT_APP_BACK_URL + '/cv');
        const res = await response.json();
        setData(res);
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (data) {
            setIsLoading(false);
        }
    }, [data]);

    if (isLoading) {
        return <Loader />;
    }

    const { hero, banner, technologies, timelineEducation, timelineExperiences, projects } = data;

    return (
        <div className='h-screen'>
            <FullPageImage title={hero.title} image={process.env.REACT_APP_BACK_URL+hero.img} />
            <Banner color='light'>
                <div className='main-title'>{banner.title}</div>
                <div className='main-description w-full lg:w-4/5 m-auto'>
                    {banner.description}
                </div>
            </Banner>
            <Banner color='dark'>
                <div className='main-title'>{technologies.title}</div>
                <div className='main-description'>
                    <TechnologiesBlock data={technologies.items}/>
                </div>
            </Banner>
            <Banner color='light'>
                <div className='grid 2xl:grid-cols-2 grid-cols-1 w-full lg:w-4/5 m-auto gap-1/5'>
                    <div className='flex flex-col gap-[40px] 2xl:items-center items-start'>
                        <div className='tracking-[2px] font-medium text-3xl mx-auto'>{timelineExperiences.title}</div>
                        <TimelineBlock data={timelineExperiences.items}/>
                    </div>
                    <div className='flex flex-col gap-[40px] 2xl:items-center items-start'>
                        <div className='tracking-[2px] font-medium text-3xl mx-auto'>{timelineEducation.title}</div>
                        <div className='items-center'>
                            <TimelineBlock data={timelineEducation.items}/>
                        </div>
                    </div>
                </div>
            </Banner>
            <Banner color='dark'>
                <div className='main-title'>{projects.title}</div>
                <div className='grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 w-full lg:w-4/5 gap-20 mx-auto'>
                    {data.projects.items.map(({name, img, link}) => <div
                        key={name}
                        className='flex flex-col gap-[10px] items-center project'
                    >
                        <div className='text-lg'>{name}</div>
                        <Link key={uuidv4()} className='project' to={link}>
                            <Image preview={false} src={process.env.REACT_APP_BACK_URL+img} alt="" width={256} height={256}/>
                        </Link>
                    </div>)}
                </div>
            </Banner>
        </div>
    );
};