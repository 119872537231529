import { Timeline } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { TimelineBlock } from '../Types/Data';

interface TimelineBlockProps {
    data: TimelineBlock[];
}

export default ({ data }: TimelineBlockProps) =>
    <Timeline
        items={[...data.map(({ title, subtitle, date }: TimelineBlock) => ({
            children: <div className='flex flex-col items-start text-left' key={uuidv4()}>
                <div className='text-2xl font-bold text-[#4d4d4d]'>{title}</div>
                <div className='text-xl font-bold text-[#6f6f6f]'>{subtitle}</div>
                <div className='text-lg font-normal text-[#6f6f6f]'>{date}</div>
            </div>,
            color: 'gray',
        })), { children: undefined, dot: <></> } ]}
    />
;